// Libs
import React, { useRef, useEffect } from 'react'
import { Group } from 'three'
// Hooks
import { Settings } from 'hooks/useAssetLoader'
// Utils
import { clamp } from '../utils/math'
// Components
import Astronaut from './components/Astronaut'
import FooterComponent from './components/footer'
import Lights from './components/Lights'
import Post from './components/Post'
import Scene from './components/Scene'
// Controllers
import CameraController from './controllers/CameraController'
import CursorHandler from './controllers/CursorHandler'
import KeyController from './controllers/KeyController'
// Global
import { Animations, app, dispatcher, Events, tweener } from './global'
import useVisualViewport from 'hooks/useVisualViewport'
import { RectInterface } from 'hooks/useElementRect'
import { useFrame } from '@react-three/fiber'

// const IS_DEV = process.env.NODE_ENV === 'development'

interface ThreeSceneProps {
  isHeaderVisible: boolean
  isFooterVisible: boolean
  headerRect: RectInterface
  footerRect: RectInterface
}

export default function ThreeScene({ isHeaderVisible, isFooterVisible, headerRect, footerRect }: ThreeSceneProps) {
  const viewport = useVisualViewport()
  const groupRef = useRef<Group>()

  // set visibility values
  useEffect(() => {
    if (groupRef?.current) {
      groupRef.current.visible = isHeaderVisible || isFooterVisible
      app.footerVisible = isFooterVisible
      app.headerVisible = isHeaderVisible
    }
  }, [isHeaderVisible, isFooterVisible])

  // dispatch intro animation on mount
  useEffect(() => {
    dispatcher.dispatchEvent({
      type: Events.ANIMATION,
      value: Animations.Intro,
    })
  }, [])

  // dispatch visible header
  useEffect(() => {
    if (isHeaderVisible) {
      dispatcher.dispatchEvent({ type: Events.ENTER_HEADER })
    }
  }, [isHeaderVisible])

  // dispatch visible footer
  useEffect(() => {
    if (isFooterVisible) {
      dispatcher.dispatchEvent({ type: Events.ENTER_FOOTER })
    }
  }, [isFooterVisible])

  useFrame((_, delta) => {
    if (!isFooterVisible && !isHeaderVisible) {
      return
    }

    if (isFooterVisible && footerRect?.y && viewport?.height) {
      const footerTop = footerRect.y - viewport.height
      app.percent = clamp(0, 1, (window.scrollY - footerTop) / viewport.height)
    }

    if (isHeaderVisible && headerRect?.height) {
      app.percent = clamp(0, 1, window.scrollY / headerRect.height)
    }

    tweener.update(delta)
  })

  return (
    <>
      <group ref={groupRef}>
        <Lights />
        <Scene />
        <Astronaut />
        <FooterComponent />
        <Post />
      </group>
      <CameraController />
      {Settings.mobile ? null : <CursorHandler />}
      {Settings.mobile ? null : <KeyController />}
    </>
  )
}
